import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarComponent } from './components/navbar/navbar.component';
import { FooterComponent } from './components/footer/footer.component';
import { RouterModule } from '@angular/router';
import { FeatureComingSoonComponent } from './components/feature-coming-soon/feature-coming-soon.component';
import { LoadingComponent } from './components/loading/loading.component';
import { InlineLoaderComponent } from './components/inline-loader/inline-loader.component';
import { SideMenuComponent } from './components/side-menu/side-menu.component';
import { AccessDeniedComponent } from './components/access-denied/access-denied.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { CustomInputComponent } from './components/custom-input/custom-input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UpgradeRequiredComponent } from './components/upgrade-required/upgrade-required.component';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { PrivilegesComponent } from './components/privileges/privileges.component';
import { DragndropDirective } from './directives/dragndrop.directive';
import { ShortNumberPipe } from './pipes/short-number.pipe';
import { ShareToSocialComponent } from './components/share-to-social/share-to-social.component';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { SharedQrCustomizeAmenitiesIconComponent } from './components/shared-qr-customize-amenities-icon/shared-qr-customize-amenities-icon.component';
import { SharedQrCustomizeSocialMediaLinksComponent } from './components/shared-qr-customize-social-media-links/shared-qr-customize-social-media-links.component';
import { ActionDeniedContactSupportComponent } from './components/action-denied-contact-support/action-denied-contact-support.component';
import { SharedMapComponent } from './components/shared-map/shared-map.component';
import { CountToModule } from 'angular-count-to';
import { SharedPieComponent } from './components/shared-pie/shared-pie.component';
import { SharedBarComponent } from './components/shared-bar/shared-bar.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { SharedQrPreviewAppComponent } from './components/shared-qr-preview/shared-qr-preview-app/shared-qr-preview-app.component';
import { SharedQrPreviewBusinessPageComponent } from './components/shared-qr-preview/shared-qr-preview-business-page/shared-qr-preview-business-page.component';
import { SharedQrPreviewCouponsComponent } from './components/shared-qr-preview/shared-qr-preview-coupons/shared-qr-preview-coupons.component';
import { SharedQrPreviewEmailComponent } from './components/shared-qr-preview/shared-qr-preview-email/shared-qr-preview-email.component';
import { SharedQrPreviewEventComponent } from './components/shared-qr-preview/shared-qr-preview-event/shared-qr-preview-event.component';
import { SharedQrPreviewFeedbackComponent } from './components/shared-qr-preview/shared-qr-preview-feedback/shared-qr-preview-feedback.component';
import { SharedQrPreviewImagesComponent } from './components/shared-qr-preview/shared-qr-preview-images/shared-qr-preview-images.component';
import { SharedQrPreviewMp3Component } from './components/shared-qr-preview/shared-qr-preview-mp3/shared-qr-preview-mp3.component';
import { SharedQrPreviewPdfComponent } from './components/shared-qr-preview/shared-qr-preview-pdf/shared-qr-preview-pdf.component';
import { SharedQrPreviewRatingsComponent } from './components/shared-qr-preview/shared-qr-preview-ratings/shared-qr-preview-ratings.component';
import { SharedQrPreviewSmsComponent } from './components/shared-qr-preview/shared-qr-preview-sms/shared-qr-preview-sms.component';
import { SharedQrPreviewSocialMediaComponent } from './components/shared-qr-preview/shared-qr-preview-social-media/shared-qr-preview-social-media.component';
import { SharedQrPreviewTextComponent } from './components/shared-qr-preview/shared-qr-preview-text/shared-qr-preview-text.component';
import { SharedQrPreviewVacancyComponent } from './components/shared-qr-preview/shared-qr-preview-vacancy/shared-qr-preview-vacancy.component';
import { SharedQrPreviewVcardComponent } from './components/shared-qr-preview/shared-qr-preview-vcard/shared-qr-preview-vcard.component';
import { SharedQrPreviewVcardProComponent } from './components/shared-qr-preview/shared-qr-preview-vcard-pro/shared-qr-preview-vcard-pro.component';
import { SharedQrPreviewVideoComponent } from './components/shared-qr-preview/shared-qr-preview-video/shared-qr-preview-video.component';
import { SharedQrPreviewWebsiteComponent } from './components/shared-qr-preview/shared-qr-preview-website/shared-qr-preview-website.component';
import { SharedQrPreviewComponent } from './components/shared-qr-preview/shared-qr-preview.component';
import { RatingModule } from 'ng-starrating';
import { SharedQrChangeBannerImageComponent } from './components/shared-qr-change-banner-image/shared-qr-change-banner-image.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { SharedPopupPreviewLightboxComponent } from './components/shared-popup-preview/shared-popup-preview-lightbox/shared-popup-preview-lightbox.component';
import { SharedPopupPreviewSlideinComponent } from './components/shared-popup-preview/shared-popup-preview-slidein/shared-popup-preview-slidein.component';
import { SharedPopupPreviewBigsidebarComponent } from './components/shared-popup-preview/shared-popup-preview-bigsidebar/shared-popup-preview-bigsidebar.component';
import { SharedPopupPreviewSuccesspageComponent } from './components/shared-popup-preview/shared-popup-preview-successpage/shared-popup-preview-successpage.component';
import { SharedPopupComponent } from './components/shared-popup/shared-popup.component';
import { SharedQrPreviewUrlComponent } from './components/shared-qr-preview/shared-qr-preview-url/shared-qr-preview-url.component';
import { SharedQrPreviewBitcoinsComponent } from './components/shared-qr-preview/shared-qr-preview-bitcoins/shared-qr-preview-bitcoins.component';
import { SvgIconComponent } from './components/shared-svg-component/shared-svg.component';
import { SharedQrPreviewWifiComponent } from './components/shared-qr-preview/shared-qr-preview-wifi/shared-qr-preview-wifi.component';
import { SharedBounceModalComponent } from './components/shared-bounce-modal/shared-bounce-modal.component';
import { SharedQrPreviewPetComponent } from './components/shared-qr-preview/shared-qr-preview-pet/shared-qr-preview-pet.component';
import { SharedQrPreviewLocationComponent } from './components/shared-qr-preview/shared-qr-preview-location/shared-qr-preview-location.component';
import { PublicInformationPageComponent } from './components/public-information-page/public-information-page.component';
import { SharedQrPreviewRestaurantMenuComponent } from './components/shared-qr-preview/shared-qr-preview-restaurant-menu/shared-qr-preview-restaurant-menu.component';
import { SharedQrCustomizeAllergensIconComponent } from './components/shared-qr-customize-allergens-icon/shared-qr-customize-allergens-icon.component';
import { SharedQrPreviewLongPlainTextComponent } from './components/shared-qr-preview/shared-qr-preview-long-plain-text/shared-qr-preview-long-plain-text.component';
import { CountdownComponent } from './components/countdown/countdown.component';
import { SharedWhyChooseUsComponent } from './components/shared-why-choose-us/shared-why-choose-us.component';
import { SharedHowItWorksComponent } from './components/shared-how-it-works/shared-how-it-works.component';
import { SharedQrPreviewMemoriesComponent } from './components/shared-qr-preview/shared-qr-preview-memories/shared-qr-preview-memories.component';
import { SharedErrorOccurredComponent } from './components/shared-error-occurred/shared-error-occurred.component';
import { SharedBillingAddressFormComponent } from './components/shared-billing-address-form/shared-billing-address-form.component';
import { TxtRotateComponent } from './components/txt-rotate/txt-rotate.component';
import { LanguageSwitchComponent } from './components/language-switch/language-switch.component';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
  declarations: [
    NavbarComponent,
    FooterComponent,
    FeatureComingSoonComponent,
    LoadingComponent,
    InlineLoaderComponent,
    SideMenuComponent,
    AccessDeniedComponent,
    PageNotFoundComponent,
    CustomInputComponent,
    UpgradeRequiredComponent,
    PaginatorComponent,
    PrivilegesComponent,
    DragndropDirective,
    ShortNumberPipe,
    ShareToSocialComponent,
    SafeUrlPipe,
    SharedQrCustomizeAmenitiesIconComponent,
    SharedQrCustomizeSocialMediaLinksComponent,
    ActionDeniedContactSupportComponent,
    SharedMapComponent,
    SharedPieComponent,
    SharedBarComponent,
    SharedQrPreviewAppComponent,
    SharedQrPreviewBusinessPageComponent,
    SharedQrPreviewCouponsComponent,
    SharedQrPreviewEmailComponent,
    SharedQrPreviewEventComponent,
    SharedQrPreviewFeedbackComponent,
    SharedQrPreviewImagesComponent,
    SharedQrPreviewMp3Component,
    SharedQrPreviewPdfComponent,
    SharedQrPreviewRatingsComponent,
    SharedQrPreviewSmsComponent,
    SharedQrPreviewSocialMediaComponent,
    SharedQrPreviewTextComponent,
    SharedQrPreviewVacancyComponent,
    SharedQrPreviewVcardComponent,
    SharedQrPreviewVcardProComponent,
    SharedQrPreviewVideoComponent,
    SharedQrPreviewWebsiteComponent,
    SharedQrPreviewComponent,
    SharedQrChangeBannerImageComponent,
    SharedPopupPreviewLightboxComponent,
    SharedPopupPreviewSlideinComponent,
    SharedPopupPreviewBigsidebarComponent,
    SharedPopupPreviewSuccesspageComponent,
    SharedPopupComponent,
    SharedQrPreviewUrlComponent,
    SharedQrPreviewBitcoinsComponent,
    SvgIconComponent,
    SharedQrPreviewWifiComponent,
    SharedBounceModalComponent,
    PublicInformationPageComponent,
    SharedQrPreviewPetComponent,
    SharedQrPreviewLocationComponent,
    PublicInformationPageComponent,
    SharedQrPreviewRestaurantMenuComponent,
    SharedQrCustomizeAllergensIconComponent,
    SharedQrPreviewLongPlainTextComponent,
    CountdownComponent,
    SharedWhyChooseUsComponent,
    SharedHowItWorksComponent,
    SharedQrPreviewMemoriesComponent,
    SharedErrorOccurredComponent,
    SharedBillingAddressFormComponent,
    TxtRotateComponent,
    LanguageSwitchComponent
  ],
  imports: [
    RatingModule,
    FormsModule,
    CommonModule,
    RouterModule,
    CountToModule,
    NgSelectModule,
    TranslateModule,
    ImageCropperModule,
    ReactiveFormsModule,
    NgxDaterangepickerMd.forRoot(),
  ],
  exports: [
    SafeUrlPipe,
    ShortNumberPipe,
    SvgIconComponent,
    CountdownComponent,
    DragndropDirective,
    TxtRotateComponent,
    PrivilegesComponent,
    PaginatorComponent,
    SharedPopupComponent,
    LanguageSwitchComponent,
    UpgradeRequiredComponent,
    SharedHowItWorksComponent,
    SharedWhyChooseUsComponent,
    SharedQrPreviewMp3Component,
    SharedQrPreviewPdfComponent,
    SharedQrPreviewSmsComponent,
    SharedQrPreviewEmailComponent,
    SharedQrPreviewEventComponent,
    SharedQrPreviewImagesComponent,
    SharedQrPreviewRatingsComponent,
    SharedQrPreviewCouponsComponent,
    SharedQrPreviewFeedbackComponent,
    SharedQrChangeBannerImageComponent,
    SharedQrPreviewSocialMediaComponent,
    SharedQrPreviewTextComponent,
    SharedQrPreviewVacancyComponent,
    SharedQrPreviewVcardComponent,
    SharedQrPreviewVcardProComponent,
    SharedQrPreviewVideoComponent,
    SharedQrPreviewWebsiteComponent,
    SharedQrPreviewComponent,
    SharedBillingAddressFormComponent,
    SharedQrPreviewSocialMediaComponent,
    SharedQrPreviewBusinessPageComponent,
    CustomInputComponent,
    AccessDeniedComponent,
    PageNotFoundComponent,
    SideMenuComponent,
    NavbarComponent,
    FooterComponent,
    ShareToSocialComponent,
    LoadingComponent,
    InlineLoaderComponent,
    SharedQrPreviewAppComponent,
    SharedQrCustomizeAmenitiesIconComponent,
    SharedQrCustomizeSocialMediaLinksComponent,
    ActionDeniedContactSupportComponent,
    SharedMapComponent,
    SharedBarComponent,
    SharedPieComponent,
    SharedBounceModalComponent,
    SharedErrorOccurredComponent,
    PublicInformationPageComponent,
    SharedQrPreviewRestaurantMenuComponent,
    SharedQrCustomizeAllergensIconComponent,
    SharedQrPreviewLongPlainTextComponent
  ]
})
export class SharedModule { }
