import moment from 'moment';
import visitorInfo from "visitor-info";
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG } from '../../core/config/config';
import { environment } from 'src/environments/environment';
import { DeviceDetectorService } from 'ngx-device-detector';
import { CoreService } from '../../core/services/core.service';
import { IAppConfig } from '@app/store/models/config.interface';
import { firstValueFrom, lastValueFrom, map, Observable } from 'rxjs';
import { SessionInfo } from '@app/store/models/session-info.interface';
import { SocialHelper } from '@app/store/models/social-helper.interface';
import { FunctionsService } from '@app/modules/core/services/functions.service';
import { QrGeneratorAudio } from '@app/store/models/qr-generator-audio.interface';
import { QrGeneratorNavigator } from '@app/store/models/qr-generator-navigator.interface';
import { PopupGeneratorSlidein } from '@app/store/models/popup-generator-slidein.interface';
import { UserQrsRequestFilters } from '@app/store/models/user-qrs-request-filters.interface';
import { PopupGeneratorLightbox } from '@app/store/models/popup-generator-lightbox.interface';
import { QrGeneratorNavigatorSteps } from '@app/store/enums/qr-generator-navigator-steps.enum';
import { PopupGeneratorNavigator } from '@app/store/models/popup-generator-navigator.interface';
import { PreparedCryptoCurrencies } from '@app/store/models/prepared-crypto-currencies.interface';
import { PopupGeneratorBigSideBar } from '@app/store/models/popup-generator-big-sidebar.interface';
import { PopupDisplayConfiguration } from '@app/store/models/popup-display-configuration.interface';
import { ReferralHistoryRecordInterface } from '@app/store/models/referral-history-record.interface';
import { PopupGeneratorSuccesspage } from '@app/store/models/popup-generator-success-page.interface';
import { QR_GENERATED_STYLE_TEMPLATE } from '@app/store/models/qr-generated-style-template.interface';
import { QrGeneratorSOCIALS_TEMPLATE } from '@app/store/models/qr-generator-socials-template.interface';
import { NewOffTheShelfQrCodesInterface } from '@app/store/models/new-off-the-shelf-qr-codes.interface';
import { UserReferralsRequestFilters } from '@app/store/models/user-referral-request-filters.interface';
import { PreparedPopupGeneratedRecordInterface } from '@app/store/models/prepared-popup-generated-record.interface';
import { UserOffTheShelfQrCodesRequestFilters } from '@app/store/models/user-off-the-shelf-qr-codes-request-filters.interface';
import { QrGeneratorNavigatorCategories, QrFrameSetting, QrGeneratorUpdateModes, DynamicQrPageThemeType, QrCodeTypes, QrGeneratorNavigatorRESTAURANTMENU, QrGeneratorSOCIALS, SocialsInterfaceTypes, WiFiEncryptionTypes, QrGeneratorNavigatorWIFI, UserResourcesQrs, AppStores, APIResponse, BatchOperationInterface, PopupGeneratorNavigatorInputTypes, PreparedQrGeneratedRecordInterface, ProductPriceInterface, QR_GENERATOR_LOCATION, QrGeneratorNavigatorAPP, QrGeneratorNavigatorBUSINESSPAGE, QrGeneratorNavigatorBUSINESSPAGE_OPENING_HOURS, QrGeneratorNavigatorBitcoins, QrGeneratorNavigatorCOUPONS, QrGeneratorNavigatorEMAIL, QrGeneratorNavigatorEVENT, QrGeneratorNavigatorFEEDBACK, QrGeneratorNavigatorIMAGES, QrGeneratorNavigatorLOCATION, QrGeneratorNavigatorLONGPLAINTEXT, QrGeneratorNavigatorMEMORIES, QrGeneratorNavigatorMP3, QrGeneratorNavigatorPDF, QrGeneratorNavigatorPET, QrGeneratorNavigatorRATING, QrGeneratorNavigatorSMS, QrGeneratorNavigatorSOCIALS, QrGeneratorNavigatorTEXT, QrGeneratorNavigatorURL, QrGeneratorNavigatorVACANCY, QrGeneratorNavigatorVCARD, QrGeneratorNavigatorVCARDPRO, QrGeneratorNavigatorVIDEOS, QrGeneratorNavigatorWEBSITE, QrTheme, initQRStyle, PopupCategories, UserResourcesPopups, SystemUserTypes, PublicUserDetails, PublicQrDetails, BitcoinTypes, Paginated, SupportedLanguage, QrGeneratorFrameTypes } from 'shared-library';

@Injectable({
  providedIn: 'root'
})
export class UserQrGeneratorService {


  constructor(
    private http: HttpClient,
    private device: DeviceDetectorService,
    private functionService: FunctionsService,
    @Inject(APP_CONFIG) private config: IAppConfig
  ) { }

  async crawlURL(url: string): Promise<{ title: string; embed_url: string; fav_icon_url: string; url: string; }> {
    return (await this.http.post(this.config.apiEndpoints.resources.crawl_link, { url }).toPromise() as any).data
  }

  getQrs(url: string, data: UserQrsRequestFilters): Observable<Paginated<PreparedQrGeneratedRecordInterface>> {
    return this.http.get<{ history: Paginated<PreparedQrGeneratedRecordInterface>; success: boolean; }>(url, { params: data as any }).pipe(map(response => response.history));
  }

  getOffTheShelfQrCodes(url: string, data: UserOffTheShelfQrCodesRequestFilters): Observable<Paginated<BatchOperationInterface>> {
    return this.http.get<{ data: Paginated<BatchOperationInterface>; success: boolean; }>(url, { params: data as any }).pipe(map(response => response.data));
  }

  getReferralHistory(url: string, data: UserReferralsRequestFilters): Observable<Paginated<ReferralHistoryRecordInterface>> {
    return this.http.get<{ data: Paginated<ReferralHistoryRecordInterface>; success: boolean; }>(url, { params: data as any }).pipe(map(response => response.data));
  }

  getPopups(url: string, data: UserQrsRequestFilters): Observable<Paginated<PreparedPopupGeneratedRecordInterface>> {
    return this.http.get<{ history: Paginated<PreparedPopupGeneratedRecordInterface>; success: boolean; }>(url, { params: data as any }).pipe(map(response => response.history));
  }

  getQRThemes(url: string): Observable<QR_GENERATED_STYLE_TEMPLATE[]> {
    return this.http.get<{ templates: QR_GENERATED_STYLE_TEMPLATE[]; success: boolean; }>(url).pipe(map(response => response.templates));
  }

  getSocialTemplates(): QrGeneratorSOCIALS_TEMPLATE[] {
    return [
      {
        type: SocialsInterfaceTypes.website,
        icon: "bi bi-globe2",
        name: "Website",
        input_type: PopupGeneratorNavigatorInputTypes.URL,
        placeholder: "https://myawesomewebsite.com",
        favicon: "assets/social-media-logos/website.png"
      },
      {
        type: SocialsInterfaceTypes.mail,
        icon: "bi bi-envelope",
        name: "Email",
        input_type: PopupGeneratorNavigatorInputTypes.EMAIL,
        placeholder: "myname@mydomain.com",
        favicon: "assets/social-media-logos/mail.png"
      },
      {
        type: SocialsInterfaceTypes.instagram,
        icon: "bi bi-instagram",
        name: "Instagram",
        input_type: PopupGeneratorNavigatorInputTypes.URL,
        placeholder: "https://instagram.com/...",
        favicon: "assets/social-media-logos/instagram.png"
      },
      {
        type: SocialsInterfaceTypes.youtube,
        icon: "bi bi-youtube",
        input_type: PopupGeneratorNavigatorInputTypes.URL,
        name: "YouTube",
        placeholder: "https://youtube.com/channel/...",
        favicon: "assets/social-media-logos/youtube.png"
      },
      {
        type: SocialsInterfaceTypes.facebook,
        icon: "bi bi-facebook",
        input_type: PopupGeneratorNavigatorInputTypes.URL,
        name: "Facebook",
        placeholder: "https://facebook.com/...",
        favicon: "assets/social-media-logos/facebook.png"
      },
      {
        type: SocialsInterfaceTypes.linkedin,
        icon: "bi bi-linkedin",
        input_type: PopupGeneratorNavigatorInputTypes.URL,
        name: "LinkedIn",
        placeholder: "https://linkedin.com/in/...",
        favicon: "assets/social-media-logos/linkedin.png"
      },
      {
        type: SocialsInterfaceTypes.twitter,
        icon: "bi bi-twitter",
        input_type: PopupGeneratorNavigatorInputTypes.URL,
        name: "Twitter",
        placeholder: "https://twitter.com/...",
        favicon: "assets/social-media-logos/twitter.png"
      },
      {
        type: SocialsInterfaceTypes.whatsapp,
        name: "WhatsApp",
        icon: "bi bi-whatsapp",
        input_type: PopupGeneratorNavigatorInputTypes.TEXT,
        placeholder: "00 00000 0000",
        favicon: "assets/social-media-logos/whatsapp.png"
      },
      {
        type: SocialsInterfaceTypes.tiktok,
        icon: "",
        input_type: PopupGeneratorNavigatorInputTypes.URL,
        name: "TikTok",
        placeholder: "https://tiktok.com/...",
        favicon: "assets/social-media-logos/tiktok.png"
      },
      {
        type: SocialsInterfaceTypes.onlyfans,
        icon: "",
        input_type: PopupGeneratorNavigatorInputTypes.URL,
        name: "OnlyFans",
        placeholder: "https://onlyfans.com/...",
        favicon: "assets/social-media-logos/onlyfans.png"
      },
      {
        type: SocialsInterfaceTypes.pinterest,
        icon: "",
        input_type: PopupGeneratorNavigatorInputTypes.URL,
        name: "Pinterest",
        placeholder: "https://pinterest.com/...",
        favicon: "assets/social-media-logos/pinterest.png"
      }
    ]
  }

  initQRCreatorData(selected_category: QrGeneratorNavigatorCategories): QrGeneratorNavigator {
    return {
      id: "",
      processing: false,
      style_settings: initQRStyle(),
      activation_code_ots_qr: "",
      active_step: QrGeneratorNavigatorSteps.START,
      app_data: null,
      sms_data: null,
      url_data: null,
      pet_data: null,
      pdf_data: null,
      mp3_data: null,
      text_data: null,
      wifi_data: null,
      vcard_data: null,
      email_data: null,
      event_data: null,
      images_data: null,
      rating_data: null,
      videos_data: null,
      vacancy_data: null,
      website_data: null,
      socials_data: null,
      coupons_data: null,
      bitcoin_data: null,
      vcardpro_data: null,
      feedback_data: null,
      location_data: null,
      memories_data: null,
      businesspage_data: null,
      restaurant_menu_data: null,
      long_plain_text_data: null,
      selected_category
    }
  }

  initPopupCreatorData(selected_category: PopupCategories): PopupGeneratorNavigator {
    return {
      id: "",
      processing: false,
      slidein_data: null,
      lightbox_data: null,
      bigsidebar_data: null,
      successpage_data: null,
      active_step: QrGeneratorNavigatorSteps.START,
      selected_category
    }
  }

  async qrResourcesHandler(coreService: CoreService, force = false) {
    if (coreService.isLoggedIn() && coreService.session_data.user_details.user_category !== SystemUserTypes.ADMIN) {
      if (force || (!coreService.session_data.cache.qrs.length && (coreService.session_data.user_details.current_organization_details.current_team_details.stats.qrs.active + coreService.session_data.user_details.current_organization_details.current_team_details.stats.qrs.inactive))) {
        const url = this.config.apiEndpoints.user.resources.replace("/{id}", ``)
        const { data } = await lastValueFrom(this.http.get<{ data: UserResourcesQrs[] }>(`${url}qrs`))
        coreService.session_data.cache.qrs = data
      }
      if (force || (!coreService.session_data.cache.popups.length && (coreService.session_data.user_details.current_organization_details.current_team_details.stats.popups.active + coreService.session_data.user_details.current_organization_details.current_team_details.stats.popups.inactive))) {
        const url = this.config.apiEndpoints.user.resources.replace("/{id}", ``)
        const { data } = await lastValueFrom(this.http.get<{ data: UserResourcesPopups[] }>(`${url}popups`))
        coreService.session_data.cache.popups = data
      }
      coreService.setSessionData(coreService.session_data)
    }
  }

  initSocialMediaCategoryData(): QrGeneratorNavigatorSOCIALS {
    return {
      qr_name: `SOCIAL_MEDIA ${moment().format('YYYY/MM/DD')}`,
      colors: {
        text_color: "#FFFFFF",
        primary_color: "#2C5F2D",
        secondary_color: "#97BC62FF"
      },
      banner: {
        style_title: "FOLLOW US",
        style: "style1",
        url: "",
        urls: [],
        deleted: [],
      },
      share_on: false,
      show_qr_code: false,
      headings: {
        main: "We create content related to new tech and gadgets.",
        meta: "Check out the links to all our videos and social platforms below.",
      },
      socials: []
    }
  }

  initVCardProCategoryData(): QrGeneratorNavigatorVCARDPRO {
    return {
      email: "",
      mobile: "",
      company: "",
      website: "",
      summary: "",
      full_name: "",
      designation: "",
      profile_url: `${environment.app_url_full}assets/layout-profile.jpeg`,
      location: this.initLocation(),
      theme: {
        type: DynamicQrPageThemeType.ThemeType1,
        preview_url: `${environment.app_url_full}assets/qr-generator/vcard-pro-style-1.png`
      },
      logo: {
        show: false,
        url: `${environment.app_url_full}assets/your-logo.png`
      },
      colors: {
        text_color: "#FFFFFF",
        primary_color: "#242333",
        secondary_color: "#E7A14F"
      },
      qr_name: `VCARD_PRO ${moment().format('YYYY/MM/DD')}`,
      savable: false,
      share_on: false,
      show_qr_code: false,
      socials: []
    }
  }

  initLocationCategoryData(trackable: boolean): QrGeneratorNavigatorLOCATION {
    return {
      url: '',
      latitude: null,
      longitude: null,
      use_lat_long: !trackable,
      location: this.initLocation(),
      qr_name: `LOCATION ${moment().format('YYYY/MM/DD')}`,
    }
  }

  initPetCategoryData(): QrGeneratorNavigatorPET {
    return {
      summary: "",
      pet_name: "",
      logo: {
        show: false,
        url: `${environment.app_url_full}assets/your-logo.png`
      },
      profile_url: `${environment.app_url_full}assets/layout-dog.jpg`,
      theme: {
        type: DynamicQrPageThemeType.ThemeType1,
        preview_url: `${environment.app_url_full}assets/qr-generator/pet-style-1.png`
      },
      language: {
        switchable: false,
        default: SupportedLanguage.English
      },
      owner_details: {
        name: "",
        email: "",
        mobile: "",
        website: "",
        savable: false,
        notify_on_qr_scan: false,
        location: this.initLocation(),
      },
      colors: {
        text_color: "#FFFFFF",
        primary_color: "#242333",
        secondary_color: "#E7A14F"
      },
      show_send_location: true,
      qr_name: `PET ${moment().format('YYYY/MM/DD')}`,
      share_on: false,
      show_qr_code: false,
      headings: {
        main: "",
        meta: ""
      },
      other_information: [],
      gallery: {
        show: false,
        images: {
          grid: true,
          list: [],
          deleted: []
        },
      }
    }
  }

  visitInfo() {
    const qr_mode_indicator = "?mode=qr"
    const click_data = {
      country: "",
      os: "Unknown",
      device: "Bot",
      browser: "Unknown",
      country_code: "Unknown",
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      qr_scanned: window.location.href.includes(qr_mode_indicator),
    }

    const deviceInfo = this.device.getDeviceInfo();
    if (this.device.isMobile()) {
      click_data.device = "Mobile";
    } else if (this.device.isTablet()) {
      click_data.device = "Tablet";
    } else if (this.device.isDesktop()) {
      click_data.device = "Desktop";
    }
    click_data.os = deviceInfo.os;
    click_data.browser = deviceInfo.browser;

    let visitor_info = visitorInfo();
    click_data.timezone = visitor_info.timezone.name;
    if (visitor_info.country) {
      click_data.country = visitor_info.country.name;
      if (visitor_info.country.alpha2) {
        click_data.country_code = visitor_info.country.alpha2;
      }
    }
    return click_data
  }

  initSocialHelper(): SocialHelper {
    return {
      description: "",
      title: 'QR URL',
      url: '#',
      qr_url: ''
    }
  }

  initCouponsCategoryData(): QrGeneratorNavigatorCOUPONS {
    return {
      qr_name: `COUPON ${moment().format('YYYY/MM/DD')}`,
      banner_url: `${environment.app_url_full}assets/banner/coupon-banner.jpg`,
      share_on: false,
      show_qr_code: false,
      colors: {
        text_color: "#FFFFFF",
        primary_color: "#1B4FAD",
        secondary_color: "#CD30A1"
      },
      deleted: [],
      coupon: {
        code: "",
        headline: "50% OFF",
        show: true,
        showing: false,
        custom_headline: "",
        expiry_date: ""
      },
      tnc: "",
      company_name: "",
      headings: {
        main: "",
        meta: ""
      },
      location: this.initLocation(),
      ctas: {
        reveal_coupon: {
          text: "GET COUPON"
        },
        redeem_coupon: {
          url: "",
          text: "REDEEM NOW",
          show: true
        },
        location: {
          url: "",
          text: "VIEW LOCATION",
          show: true
        }
      }
    }
  }

  initLocation(): QR_GENERATOR_LOCATION {
    return {
      address: "",
      location_url: "",
      from_form: false,
      form: {
        zip: "",
        city: "",
        state: "",
        street: "",
        country: null
      },
      google: {
        url: "",
        lat: 0,
        long: 0,
        rating: 0,
        formatted_address: "",
        international_phone_number: "",
      }
    }
  }

  initBusinessPageCategoryData(): QrGeneratorNavigatorBUSINESSPAGE {
    return {
      share_on: false,
      show_qr_code: false,
      qr_name: `BUSINESS_PAGE ${moment().format('YYYY/MM/DD')}`,
      banner_url: `${environment.app_url_full}assets/banner/zenith-studios-gym.jpeg`,
      company: "",
      headings: {
        main: "",
        meta: ""
      },
      cta: {
        url: "",
        show: true,
        title: "VIEW MENU",
        custom_title: ""
      },
      socials: [],
      deleted: [],
      opening_hours: this.initOpeningHours(),
      location: this.initLocation(),
      colors: {
        text_color: "#FFFFFF",
        primary_color: "#2C5F2D",
        secondary_color: "#97BC62FF"
      },
      full_name: "",
      email: "",
      mobile: "",
      website: "",
      facilities: []
    }
  }

  initLongPlainTextCategoryData(): QrGeneratorNavigatorLONGPLAINTEXT {
    return {
      text: "",
      deleted: [],
      share_on: false,
      show_qr_code: false,
      qr_name: `LONG_TEXT ${moment().format('YYYY/MM/DD')}`,
      banner_url: `${environment.app_url_full}assets/banner/long_text_banner.png`,
    }
  }

  initRestaurantMenu(currency?: string): QrGeneratorNavigatorRESTAURANTMENU {
    this.functionService.logToConsole(currency)
    return {
      share_on: false,
      show_qr_code: false,
      qr_name: `RESTAURANT_MENU ${moment().format('YYYY/MM/DD')}`,
      headings: {
        main: "",
        meta: ""
      },
      socials: [],
      colors: {
        text_color: "#FFFFFF",
        primary_color: "#191919",
        secondary_color: "#F5BC2A"
      },
      menu: {
        deleted: [],
        sections: [],
        currency: currency || "USD",
        logo: `${environment.app_url_full}assets/banner/burger.jpg`,
      }
    }
  }

  private initOpeningHours(): QrGeneratorNavigatorBUSINESSPAGE_OPENING_HOURS[] {
    return [
      {
        name: "Monday",
        opened: true,
        hours: [
          {
            position: 1,
            from: "08:00",
            to: "20:00",
          }
        ]
      },
      {
        name: "Tuesday",
        opened: true,
        hours: [
          {
            position: 1,
            from: "08:00",
            to: "20:00",
          }
        ]
      },
      {
        name: "Wednesday",
        opened: true,
        hours: [
          {
            position: 1,
            from: "08:00",
            to: "20:00",
          }
        ]
      },
      {
        name: "Thursday",
        opened: true,
        hours: [
          {
            position: 1,
            from: "08:00",
            to: "20:00",
          }
        ]
      },
      {
        name: "Friday",
        opened: true,
        hours: [
          {
            position: 1,
            from: "08:00",
            to: "20:00",
          }
        ]
      },
      {
        name: "Saturday",
        opened: false,
        hours: [
          {
            position: 1,
            from: "08:00",
            to: "20:00",
          }
        ]
      },
      {
        name: "Sunday",
        opened: false,
        hours: [
          {
            position: 1,
            from: "08:00",
            to: "20:00",
          }
        ]
      }
    ]
  }

  initFeedbackCategoryData(): QrGeneratorNavigatorFEEDBACK {
    return {
      logo: {
        show: true,
        url: `${environment.app_url_full}assets/your-logo.png`
      },
      qr_name: `FEEDBACK ${moment().format('YYYY/MM/DD')}`,
      company_name: "",
      share_on: false,
      show_qr_code: false,
      colors: {
        text_color: "#FFFFFF",
        primary_color: "#2C5F2D",
        secondary_color: "#97BC62FF"
      },
      headings: {
        main: "",
        meta: ""
      },
      feedback: {
        contact: {
          name: "",
          email: "",
          mobile: ""
        },
        questions: [
          {
            title: '',
            icon: 'bi bi-grid',
            id: this.functionService.randomNumber(),
            feedback: {
              rating: 0,
              description: ""
            },
            subs: []
          }]
      },
      contact: {
        email: "",
        website: ""
      }
    }
  }

  initRatingCategoryData(): QrGeneratorNavigatorRATING {
    return {
      qr_name: `RATING ${moment().format('YYYY/MM/DD')}`,
      banner_url: `${environment.app_url_full}assets/banner/hotel.jpg`,
      company_name: "",
      share_on: false,
      show_qr_code: false,
      deleted: [],
      colors: {
        text_color: "#FFFFFF",
        primary_color: "#2C5F2D",
        secondary_color: "#97BC62FF"
      },
      rating: {
        rating: 0,
        description: ""
      },
      socials: [],
      headings: {
        main: "",
        meta: ""
      },
      contact: {
        email: "",
        website: ""
      }
    }
  }

  initVideoCategoryData(): QrGeneratorNavigatorVIDEOS {
    return {
      qr_name: `YOUTUBE_VIDEOS ${moment().format('YYYY/MM/DD')}`,
      company_name: "",
      share_on: false,
      show_qr_code: false,
      logo: {
        show: true,
        url: `${environment.app_url_full}assets/your-logo.png`
      },
      colors: {
        text_color: "#FFFFFF",
        primary_color: "#2C5F2D",
        secondary_color: "#97BC62FF"
      },
      cta: {
        url: "",
        show: true,
        title: "LEARN MORE",
        custom_title: ""
      },
      videos: [],
      socials: [],
      headings: {
        main: "",
        meta: ""
      }
    }
  }

  initEventCategoryData(): QrGeneratorNavigatorEVENT {
    return {
      share_on: false,
      show_qr_code: false,
      qr_name: `EVENT ${moment().format('YYYY/MM/DD')}`,
      banner_url: `${environment.app_url_full}assets/banner/meeting.jpg`,
      socials: [],
      deleted: [],
      colors: {
        text_color: "#FFFFFF",
        primary_color: "#2C5F2D",
        secondary_color: "#97BC62FF"
      },
      facilities: [],
      cta: {
        url: "",
        show: true,
        title: "GET TICKETS",
        custom_title: ""
      },
      event: {
        about: "",
        venue: "",
        organizer: "",
        duration: {
          to: "",
          from: "",
          all_day: false
        },
        contact: {
          name: "",
          email: "",
          mobile: "",
          website: "",
        }
      },

      location: this.initLocation(),
      headings: {
        main: "",
        meta: ""
      }
    }
  }

  initPDFCategoryData(): QrGeneratorNavigatorPDF {
    return {
      company_name: "",
      share_on: false,
      show_qr_code: false,
      link_directly: false,
      qr_name: `PDF ${moment().format('YYYY/MM/DD')}`,
      colors: {
        text_color: "#FFFFFF",
        primary_color: "#2C5F2D",
        secondary_color: "#97BC62FF"
      },
      pdf: {
        url: "",
        size: "",
        name: "",
        deleted: []
      },
      cta: {
        url: "",
        show: true,
        title: "VIEW PDF",
        custom_title: ""
      },
      website: "",
      headings: {
        main: "",
        meta: ""
      }
    }
  }

  initWEBSITECategoryData(): QrGeneratorNavigatorWEBSITE {
    return {
      url: "",
      qr_name: `WEBSITE ${moment().format('YYYY/MM/DD')}`,
    }
  }

  initURLCategoryData(): QrGeneratorNavigatorURL {
    return {
      url: "",
      qr_name: `URL ${moment().format('YYYY/MM/DD')}`,
    }
  }

  initAPPCategoryData(): QrGeneratorNavigatorAPP {
    return {
      qr_name: `MOBILE_APP ${moment().format('YYYY/MM/DD')}`,
      share_on: false,
      company_name: "",
      show_qr_code: false,
      colors: {
        text_color: "#FFFFFF",
        primary_color: "#2C5F2D",
        secondary_color: "#97BC62FF"
      },
      app: {
        logo: `${environment.app_url_full}assets/logos/app.png`,
        name: "",
        links: [
          {
            url: "",
            position: 0,
            type: AppStores.APPSTORE,
            name: "App Store",
            logo: `${environment.app_url_full}assets/logos/app-store.png`,
          }
        ],
        deleted: []
      },
      cta: {
        url: "",
        show: true,
        title: "LEARN MORE",
        custom_title: ""
      },
      website: "",
      headings: {
        main: "",
        meta: ""
      }
    }
  }

  initImagesCategoryData(): QrGeneratorNavigatorIMAGES {
    return {
      share_on: false,
      show_qr_code: false,
      qr_name: `GALLERY ${moment().format('YYYY/MM/DD')}`,
      colors: {
        text_color: "#FFFFFF",
        primary_color: "#2C5F2D",
        secondary_color: "#97BC62FF"
      },
      images: {
        grid: true,
        list: [],
        deleted: []
      },
      cta: {
        url: "brandqrcodes.com",
        show: true,
        title: "SEE MY WEBSITE",
        custom_title: ""
      },
      website: "",
      headings: {
        main: "",
        meta: ""
      }
    }
  }

  initMemoriesCategoryData(): QrGeneratorNavigatorMEMORIES {
    return {
      share_on: false,
      show_qr_code: false,
      downloadable: false,
      contact: {
        email: "",
        enable: false
      },
      qr_name: `MEMORIES ${moment().format('YYYY/MM/DD')}`,
      banner_url: `${environment.app_url_full}assets/banner/memories.png`,
      colors: {
        text_color: "#FFFFFF",
        primary_color: "#bd967f",
        secondary_color: "#875f48"
      },
      deleted: [],
      website: "",
      headings: {
        main: "",
        meta: ""
      }
    }
  }

  initMP3CategoryData(): QrGeneratorNavigatorMP3 {
    return {
      share_on: false,
      show_qr_code: false,
      qr_name: `MP3 ${moment().format('YYYY/MM/DD')}`,
      colors: {
        text_color: "#FFFFFF",
        primary_color: "#2C5F2D",
        secondary_color: "#97BC62FF"
      },
      mp3: {
        show_thumbnail: true,
        url: "",
        thumbnail: `${environment.app_url_full}assets/album.png`,
        name: "",
        size: "",
        deleted: [],
        downloadable: false
      },
      cta: {
        url: "",
        show: true,
        title: "BUY NOW",
        custom_title: ""
      },
      website: "",
      headings: {
        main: "",
        meta: ""
      }
    }
  }

  initVacancyCategoryData(): QrGeneratorNavigatorVACANCY {
    return {
      qr_name: `VACANCY ${moment().format('YYYY/MM/DD')}`,
      logo: {
        show: true,
        url: `${environment.app_url_full}assets/your-logo.png`
      },
      company_name: "",
      share_on: false,
      show_qr_code: false,
      colors: {
        text_color: "#FFFFFF",
        primary_color: "#75cfb8",
        secondary_color: "#03506f"
      },
      headings: {
        main: "",
        meta: ""
      },
      application: {
        contact: {
          cv: "",
          name: "",
          email: "",
        },
        summary: "",
        off_site: true,
        descriptions: ""
      },
      contact: {
        name: "",
        email: "",
        mobile: "",
        website: "",
        address: "",
        address_url: "",
      },
      cta: {
        url: "",
        show: true,
        title: "APPLY NOW",
        custom_title: ""
      },
      location: this.initLocation()
    }
  }

  staticCategories(): QrGeneratorNavigatorCategories[] {
    return [
      QrGeneratorNavigatorCategories.URL,
      QrGeneratorNavigatorCategories.SMS,
      QrGeneratorNavigatorCategories.TEXT,
      QrGeneratorNavigatorCategories.VCARD,
      QrGeneratorNavigatorCategories.EMAIL,
    ]
  }

  initVCardCategoryData(): QrGeneratorNavigatorVCARD {
    return {
      note: "",
      email: "",
      title: "",
      mobile: "",
      website: "",
      address: "",
      birthday: "",
      full_name: "",
      designation: "",
      mobile_work: "",
      mobile_other: "",
      company_name: "",
      show_profile_url: false,
      location: this.initLocation(),
      qr_name: `VCARD ${moment().format('YYYY/MM/DD')}`,
      profile_url: `${environment.app_url_full}assets/layout-profile.jpeg`
    }
  }

  initEMAILCategoryData(): QrGeneratorNavigatorEMAIL {
    return {
      body: "",
      email: "",
      subject: "",
      qr_name: `EMAIL ${moment().format('YYYY/MM/DD')}`,
    }
  }

  initBitcoinsCategoryData(): QrGeneratorNavigatorBitcoins {
    return {
      address: "",
      message: "",
      amount: null,
      cryptocurrency: BitcoinTypes.BITCOIN,
      qr_name: `BITCOIN ${moment().format('YYYY/MM/DD')}`,
    }
  }

  initWiFiCategoryData(): QrGeneratorNavigatorWIFI {
    return {
      ssid: "",
      password: "",
      hidden: false,
      encryption: WiFiEncryptionTypes.WPA,
      qr_name: `WIFI ${moment().format('YYYY/MM/DD')}`,
    }
  }

  initSMSCategoryData(): QrGeneratorNavigatorSMS {
    return {
      body: "",
      mobile: "",
      qr_name: `SMS ${moment().format('YYYY/MM/DD')}`,
    }
  }

  initTEXTCategoryData(): QrGeneratorNavigatorTEXT {
    return {
      text: "",
      qr_name: `TEXT ${moment().format('YYYY/MM/DD')}`,
    }
  }

  getDurationPrepared(price: ProductPriceInterface): string {
    return price.durations ? `every ${price.durations.unit} ${price.durations.in}` : "One off"
  }


  fillAudioPlayer(src: string, audio: QrGeneratorAudio = null): QrGeneratorAudio {
    let ret: QrGeneratorAudio = {
      voice_name: "",
      progress: 0,
      duration: 0,
      voice_gender: "",
      playing: false,
      updating: false,
      deleting: false,
      downloading: false,
      generated: src ? true : false,
      player: (audio)?.player || this.initiateAudioPlayer()
    }
    if (src) {
      ret.player.src = src
      ret.player.addEventListener('loadedmetadata', () => {
        ret.duration = ret.player.duration
      })
      ret.player.addEventListener('timeupdate', () => {
        ret.progress = parseInt(Math.ceil((ret.player.currentTime / ret.player.duration) * 100).toString())
        if (ret.progress == 100) {
          ret.playing = false
          ret.progress = 0
        }
      })
    }
    return ret
  }

  initiateAudioPlayer(): HTMLAudioElement {
    var createAudio = () => {
      try {
        return new Audio()
      } catch (e) {
        return false
      }
    };
    var audio = createAudio()
    if (audio) return audio
    return null
  }

  scrollToFirstInvalidControl(include_div = false) {
    setTimeout(() => {

      const invalid_div = () => {
        let elements = document.querySelectorAll<HTMLDivElement>(".is-invalid")
        if (elements.length) {
          elements[0].scrollIntoView({ behavior: 'smooth' })
          return true
        }
        return false
      }

      if (include_div && invalid_div()) return
      let elements = document.querySelectorAll<HTMLInputElement>(".is-invalid")
      if (elements.length) {
        elements[0].scrollTop = 0
        elements[0].focus()
        return
      }

      elements = document.querySelectorAll<HTMLInputElement>(".input-error")
      if (elements.length) {
        elements[0].scrollTop = 0
        elements[0].focus()
        return
      }

    }, 500)

  }

  chartColorRangescolors(): string[] {
    return [
      '#30B86D',
      '#3498DB',
      '#8E44AD',
      '#E74C3C',
      '#F39C12',
      '#1ABC9C',
      '#9C88FF',
      '#B8E994',
      '#F8C291',
      '#B71540',
      '#CCC1C1',
      '#2C9191',
      '#01D3B9',
      '#2D908F',
      '#7BB4EC',
      '#F45B5B',
      '#90ED7D',
      '#91E8E0',
      '#F7A45B',
      '#7BB4EC',
      '#E4D354'
    ]
  }

  private initPopupBoxConfig(): PopupDisplayConfiguration {
    return {
      time_delay: {
        seconds: 5,
        enable: true
      },
      page_scroll: {
        percentage: 0,
        enable: false
      },
      date: {
        end_date: "",
        enable: false,
        start_date: "",
      },
      days_n_hours: {
        enable: false,
        periods: this.initOpeningHours()
      },
      countries: {
        enable: false,
        countries: []
      }
    }
  }

  initLightboxCategoryData(): PopupGeneratorLightbox {
    return {
      popup_name: `LIGHT_BOX ${moment().format('YYYY/MM/DD')}`,
      banner: {
        show: true,
        deleted: [],
        url: `${environment.app_url_full}assets/banner/coupon-banner.jpg`,
      },
      config: this.initPopupBoxConfig(),
      colors: {
        text_color: "#FFFFFF",
        primary_color: "#2C5F2D",
        secondary_color: "#97BC62FF"
      },
      headings: {
        main: "",
        meta: ""
      },
      website: "",
      form: {
        inputs: [],
        cta_text: "Sign me up!",
        enable: false
      },
      cta: {
        url: "",
        show: true,
        custom_title: "",
        title: "REDEEM NOW",
      },
      no_thanks: {
        show: true,
        title: "No thanks",
      }
    }
  }

  initSlideInCategoryData(): PopupGeneratorSlidein {
    return {
      banner: {
        show: false,
        deleted: [],
        url: `${environment.app_url_full}assets/banner/shopping-cart.png`,
      },
      website: "",
      popup_name: `SLIDE_IN ${moment().format('YYYY/MM/DD')}`,
      colors: {
        text_color: "#FFFFFF",
        primary_color: "#2C5F2D",
        secondary_color: "#97BC62FF"
      },
      config: this.initPopupBoxConfig(),
      headings: {
        main: "",
        meta: ""
      },
      right_to_view: true,
      form: {
        inputs: [],
        cta_text: "Sign me up!",
        enable: false
      },
      cta: {
        url: "",
        show: true,
        custom_title: "",
        title: "REDEEM NOW",
      },
      no_thanks: {
        show: true,
        title: "No thanks",
      }
    }
  }

  initBigSideBarCategoryData(): PopupGeneratorBigSideBar {
    return {
      banner: {
        show: true,
        deleted: [],
        url: `${environment.app_url_full}assets/banner/reading.png`,
      },
      website: "",
      popup_name: `BIG_SIDE_BAR ${moment().format('YYYY/MM/DD')}`,
      colors: {
        text_color: "#FFFFFF",
        primary_color: "#2C5F2D",
        secondary_color: "#97BC62FF"
      },
      config: this.initPopupBoxConfig(),
      headings: {
        main: "",
        meta: ""
      },
      right_to_view: true,
      form: {
        inputs: [],
        enable: false,
        cta_text: "Sign up",
      },
      cta: {
        url: "",
        show: true,
        custom_title: "",
        title: "REDEEM NOW",
      },
      no_thanks: {
        show: true,
        title: "No thanks",
      }
    }
  }

  initSuccessCategoryData(): PopupGeneratorSuccesspage {
    return {
      website: "",
      banner: {
        show: true,
        deleted: [],
        url: `${environment.app_url_full}assets/banner/success.png`,
      },
      popup_name: `SUCCESS_PAGE ${moment().format('YYYY/MM/DD')}`,
      colors: {
        text_color: "#FFFFFF",
        primary_color: "#2C5F2D",
        secondary_color: "#97BC62FF"
      },
      headings: {
        main: "",
        meta: ""
      },
      cta: {
        url: "",
        show: true,
        custom_title: "",
        title: "REDEEM NOW",
      }
    }
  }

  getCryptoCurrencies(): PreparedCryptoCurrencies[] {
    return [
      {
        title: "Bitcoin",
        cryptocurrency: BitcoinTypes.BITCOIN
      },
      {
        title: "Bitcoin Cash",
        cryptocurrency: BitcoinTypes.BITCOINCASH
      },
      {
        title: "Ether",
        cryptocurrency: BitcoinTypes.ETHEREUM
      },
      {
        title: "Litecoin",
        cryptocurrency: BitcoinTypes.LITECOIN
      },
      {
        title: "Dash",
        cryptocurrency: BitcoinTypes.DASH
      },
    ]
  }

  sourceToSVG(img_src: string, w_n_h = 540, x_n_y = 40): string {
    const svg_element = document.createElementNS("http://www.w3.org/2000/svg", "svg")
    const svgimg = document.createElementNS("http://www.w3.org/2000/svg", "image");
    // new
    svgimg.setAttributeNS("http://www.w3.org/1999/xlink", 'xlink:href', img_src)
    svgimg.setAttributeNS(null, "x", `${x_n_y}`)
    svgimg.setAttributeNS(null, "y", `${x_n_y}`)
    svgimg.setAttributeNS(null, "width", `${w_n_h}`)
    svgimg.setAttributeNS(null, "height", `${w_n_h}`)
    svg_element.appendChild(svgimg)
    return this.svgToSource(svg_element)
  }

  createElementFromHTML(htmlString: string): HTMLElement {
    const div = document.createElement('div');
    div.innerHTML = htmlString.trim();
    return div.firstChild as HTMLElement;
  }

  svgToSource(svg: Element): string {
    const serializer = new XMLSerializer()
    const data = btoa(serializer.serializeToString(svg))
    return "data:image/svg+xml;base64," + data
  }

  getCustomLogoId = () => "custom_logo_id"

  getActualUrl(social: QrGeneratorSOCIALS): string {
    if (social.type === SocialsInterfaceTypes.mail) {
      return social.href || `mailto:${social.email}`
    }

    if (social.type === SocialsInterfaceTypes.whatsapp) {
      return social.href || this.functionService.prepWhatsappNo(social.mobile)
    }

    if (social.href) {
      return this.functionService.forceUrl(social.href)
    }

    return environment.app_url_full
  }

  // https://tools.ietf.org/id/draft-ietf-vcarddav-vcardrev-02.html#PHOTO
  private vcard_generator() {
    let start = "BEGIN:VCARD\r\nVERSION:3.0\r\n";
    let end = "END:VCARD";
    let data = "";

    let init = () => data = ""
    let name = (fullname: string) => {
      data += `FN;CHARSET=UTF-8:${fullname}\r\n`
      data += `N;CHARSET=UTF-8:${fullname.split(' ').reverse().join(';')};\r\n`
    }
    let cell = (cell: string) => data += `TEL;TYPE=CELL:${cell}\r\n`;
    let url = (url: string) => data += `URL;CHARSET=UTF-8:${url}\r\n`;
    let other = (work: string) => data += `TEL;TYPE=OTHER:${work}\r\n`;
    let role = (role: string) => data += `ROLE;CHARSET=UTF-8:${role}\r\n`;
    let note = (note: string) => data += `NOTE;CHARSET=UTF-8:${note}\r\n`;
    let work = (work: string) => data += `TEL;TYPE=WORK,VOICE:${work}\r\n`;
    let email = (email: string) => data += `EMAIL;CHARSET=UTF-8:${email}\r\n`;
    let title = (title: string) => data += `TITLE;CHARSET=UTF-8:${title}\r\n`;
    let organization = (org: string) => data += `ORG;CHARSET=UTF-8:${org}\r\n`;
    let address = (address: string) => data += `ADR;TYPE=home:;;${address}\r\n`;
    let thumbnail = (thumbnail: string) => data += `PHOTO;TYPE=JPEG;VALUE=URL:${thumbnail}\r\n`;
    let birthday = (birthday: string) => data += `BDAY;CHARSET=UTF-8:${moment(birthday).format('YYYYMMDD')}\r\n`;
    let get = () => `${start}${data}${end}`

    return {
      get,
      url,
      note,
      role,
      init,
      name,
      cell,
      work,
      other,
      email,
      title,
      address,
      birthday,
      thumbnail,
      organization
    }
  }

  saveVCardProToDevice(data: QrGeneratorNavigatorVCARDPRO) {
    const qr_info = this.vcard_generator()
    qr_info.name(data.full_name)
    qr_info.cell(data.mobile)
    if (data.designation) {
      qr_info.role(data.designation)
      qr_info.title(data.designation)
    }
    if (data.website) {
      qr_info.url(data.website)
    }
    if (data.email) {
      qr_info.email(data.email)
    }
    if (this.functionService.is_valid_url(data.profile_url)) {
      qr_info.thumbnail(data.profile_url)
    }
    if (data.location.address) {
      qr_info.address(data.location.address)
    }
    if (data.summary) {
      const extractContent = (s: string) => {
        var span = document.createElement('span');
        span.innerHTML = s;
        return span.textContent || span.innerText;
      };
      qr_info.note(extractContent(data.summary))
    }
    if (data.company) {
      qr_info.organization(data.company)
    }
    const vcard = qr_info.get()

    const blob = new Blob([vcard], { type: "text/vcard" });
    const url = URL.createObjectURL(blob);

    const newLink = document.createElement('a');
    newLink.download = `${data.qr_name}.vcf`;
    newLink.textContent = data.qr_name;
    newLink.href = url;
    newLink.click();
  }

  savePetOwnerInfoToDevice(data: QrGeneratorNavigatorPET) {
    const qr_info = this.vcard_generator()
    qr_info.name(`${data.owner_details.name} - (${data.pet_name})`)
    qr_info.cell(data.owner_details.mobile)
    if (data.owner_details.website) {
      qr_info.url(data.owner_details.website)
    }
    if (data.owner_details.email) {
      qr_info.email(data.owner_details.email)
    }
    if (this.functionService.is_valid_url(data.profile_url)) {
      qr_info.thumbnail(data.profile_url)
    }
    if (data.owner_details.location.address) {
      qr_info.address(data.owner_details.location.address)
    }
    if (data.summary) {
      const extractContent = (s: string) => {
        var span = document.createElement('span');
        span.innerHTML = s;
        return span.textContent || span.innerText;
      };
      qr_info.note(extractContent(data.summary))
    }
    const vcard = qr_info.get()
    const blob = new Blob([vcard], { type: "text/vcard" });
    const url = URL.createObjectURL(blob);
    const newLink = document.createElement('a');
    newLink.download = `${data.qr_name}.vcf`;
    newLink.textContent = data.qr_name;
    newLink.href = url;
    newLink.click();
  }

  saveVCardToDevice(data: QrGeneratorNavigatorVCARD) {
    const qr_info = this.vcard_generator()
    qr_info.name(data.full_name)
    qr_info.cell(data.mobile)
    if (data.designation) {
      qr_info.role(data.designation)
    }
    if (data.website) {
      qr_info.url(data.website)
    }
    if (data.mobile_work.trim()) {
      qr_info.work(data.mobile_work.trim())
    }
    if (data.mobile_other.trim()) {
      qr_info.other(data.mobile_other.trim())
    }
    if (data.birthday) {
      qr_info.birthday(data.birthday)
    }
    if (data.title) {
      qr_info.title(data.title)
    }
    if (data.email) {
      qr_info.email(data.email)
    }
    if (data.show_profile_url && this.functionService.is_valid_url(data.profile_url)) {
      qr_info.thumbnail(data.profile_url)
    }
    if (data.address) {
      qr_info.address(data.address)
    }
    if (data.note) {
      qr_info.note(data.note)
    }
    if (data.company_name) {
      qr_info.organization(data.company_name)
    }
    return qr_info.get()
  }

  async createOffTheShelfBatch(url: string, data: NewOffTheShelfQrCodesInterface): Promise<APIResponse> {
    const response = await firstValueFrom(this.http.post<APIResponse>(url, data));
    return response;
  }

  async updateOffTheShelfBatch(url: string, batch_name?: string, qr_theme?: QrTheme): Promise<BatchOperationInterface> {
    const response = await firstValueFrom(this.http.patch<{ data: BatchOperationInterface }>(url, { batch_name, qr_theme }));
    return response.data;
  }

  async checkBatchStatus(url: string): Promise<BatchOperationInterface> {
    const response = await firstValueFrom(this.http.get<{ data: BatchOperationInterface; }>(url));
    return response.data;
  }

  async downloadBatchQrCodes(url: string): Promise<string> {
    const response = await firstValueFrom(this.http.get<{ data: string; }>(url));
    return response.data;
  }

  async getOffTheShelfQrCodesBatch(url: string): Promise<BatchOperationInterface> {
    const response = await firstValueFrom(this.http.get<{ data: BatchOperationInterface; }>(url));
    return response.data;
  }

  /**
   *
   * @param id
   * @param data
   * @param mode
   * @param owner_details only pass if admin is updating
   * @returns
   */
  async qrUpdatePart(id: string, data = {}, mode: QrGeneratorUpdateModes, owner_details: PublicUserDetails = null): Promise<PreparedQrGeneratedRecordInterface> {
    const url = owner_details ? this.config.apiEndpoints.user.qrs.replace("/{id}", `/${owner_details.id}`) : this.config.apiEndpoints.user.qrs.replace("/{id}", ``)
    const response = await firstValueFrom(this.http.patch<{ history: PreparedQrGeneratedRecordInterface; }>(`${url}/${mode}/${id}`, data));
    return response.history;
  }

  async downloadMemories(id: string, owner_details: PublicUserDetails = null): Promise<{ processing: boolean; url?: string; }> {
    const url = owner_details ? this.config.apiEndpoints.user.qr_responses.replace("/{id}", `/${owner_details.id}`) : this.config.apiEndpoints.user.qr_responses.replace("/{id}", ``)
    const response = await firstValueFrom(this.http.post<{ assets: { processing: boolean; url?: string; }; }>(`${url}${id}`, { mode: 'zip' }));
    return response.assets;
  }

  getAllCategoriesObj() {
    return {
      PET: QrGeneratorNavigatorCategories.PET,
      SMS: QrGeneratorNavigatorCategories.SMS,
      APP: QrGeneratorNavigatorCategories.APP,
      MP3: QrGeneratorNavigatorCategories.MP3,
      PDF: QrGeneratorNavigatorCategories.PDF,
      URL: QrGeneratorNavigatorCategories.URL,
      TEXT: QrGeneratorNavigatorCategories.TEXT,
      WIFI: QrGeneratorNavigatorCategories.WIFI,
      VCARD: QrGeneratorNavigatorCategories.VCARD,
      EMAIL: QrGeneratorNavigatorCategories.EMAIL,
      EVENT: QrGeneratorNavigatorCategories.EVENT,
      IMAGES: QrGeneratorNavigatorCategories.IMAGES,
      VIDEOS: QrGeneratorNavigatorCategories.VIDEOS,
      RATING: QrGeneratorNavigatorCategories.RATING,
      WEBSITE: QrGeneratorNavigatorCategories.WEBSITE,
      VACANCY: QrGeneratorNavigatorCategories.VACANCY,
      COUPONS: QrGeneratorNavigatorCategories.COUPONS,
      BITCOIN: QrGeneratorNavigatorCategories.BITCOIN,
      FEEDBACK: QrGeneratorNavigatorCategories.FEEDBACK,
      LOCATION: QrGeneratorNavigatorCategories.LOCATION,
      MEMORIES: QrGeneratorNavigatorCategories.MEMORIES,
      VCARD_PRO: QrGeneratorNavigatorCategories.VCARD_PRO,
      SOCIAL_MEDIA: QrGeneratorNavigatorCategories.SOCIAL_MEDIA,
      LOCATION_PLUS: QrGeneratorNavigatorCategories.LOCATION_PLUS,
      BUSINESS_PAGE: QrGeneratorNavigatorCategories.BUSINESS_PAGE,
      RESTAURANT_MENU: QrGeneratorNavigatorCategories.RESTAURANT_MENU,
      LONG_PLAIN_TEXT: QrGeneratorNavigatorCategories.LONG_PLAIN_TEXT,
    }
  }

  getFormattedDynamicQrCategories() {
    return [
      {
        text: "APP",
        icon: "bi bi-app-indicator",
        category: QrGeneratorNavigatorCategories.APP,
      },
      {
        text: "Business Page",
        icon: "bi bi-building",
        category: QrGeneratorNavigatorCategories.BUSINESS_PAGE,
      },
      {
        text: "Coupons",
        icon: "bi bi-percent",
        category: QrGeneratorNavigatorCategories.COUPONS,
      },
      {
        text: "Event",
        icon: "bi bi-calendar-check",
        category: QrGeneratorNavigatorCategories.EVENT,
      },
      {
        text: "Feedback",
        icon: "bi bi-chat-dots",
        category: QrGeneratorNavigatorCategories.FEEDBACK,
      },
      {
        text: "Images",
        icon: "bi bi-images",
        category: QrGeneratorNavigatorCategories.IMAGES,
      },
      {
        text: "Location Plus",
        icon: "bi bi-geo-alt",
        category: QrGeneratorNavigatorCategories.LOCATION_PLUS,
      },
      {
        text: "Memories",
        icon: "bi bi-camera",
        category: QrGeneratorNavigatorCategories.MEMORIES,
      },
      {
        text: "MP3",
        icon: "bi bi-music-note-beamed",
        category: QrGeneratorNavigatorCategories.MP3,
      },
      {
        text: "PDF",
        icon: "bi bi-file-earmark-pdf",
        category: QrGeneratorNavigatorCategories.PDF,
      },
      {
        text: "Pet Tag",
        icon: "bi bi-tags",
        category: QrGeneratorNavigatorCategories.PET,
      },
      {
        text: "Rating",
        icon: "bi bi-star",
        category: QrGeneratorNavigatorCategories.RATING,
      },
      {
        text: "Restaurant Menu",
        icon: "bi bi-menu-app",
        category: QrGeneratorNavigatorCategories.RESTAURANT_MENU,
      },
      {
        text: "Long Text",
        icon: "bi bi-file-richtext",
        category: QrGeneratorNavigatorCategories.LONG_PLAIN_TEXT,
      },
      {
        text: "Social Media",
        icon: "bi bi-megaphone",
        category: QrGeneratorNavigatorCategories.SOCIAL_MEDIA,
      },
      {
        text: "Vacancy",
        icon: "bi bi-briefcase",
        category: QrGeneratorNavigatorCategories.VACANCY,
      },
      {
        text: "vCard Pro",
        icon: "bi bi-person-badge",
        category: QrGeneratorNavigatorCategories.VCARD_PRO,
      },
      {
        text: "Videos",
        icon: "bi bi-youtube",
        category: QrGeneratorNavigatorCategories.VIDEOS,
      },
    ]
  }

  activateOTS(coreService: CoreService, session_info: SessionInfo, qr_details: PublicQrDetails, currency = "", activation_code_ots_qr = ""): string | boolean {

    if (coreService.isLoggedIn()) {
      if (session_info.user_details.user_category !== SystemUserTypes.USER) {
        return 'Please sign in with a user profile.'
      }
      if (!coreService.hasPermission(session_info.user_details.user_category + '.USERMANAGEMENT.QRREADNWRITE') &&
        !coreService.hasPermission(session_info.user_details.user_category + '.USERMANAGEMENT.QRFULLACCESS')) {
        return "Your current logged in profile doesn't have permission to create qr codes."
      }
    }

    const data: QrGeneratorNavigator = this.initQRCreatorData(qr_details.code_category)
    data.id = qr_details.id
    data.selected_category = qr_details.code_category
    if (data.selected_category === QrGeneratorNavigatorCategories.APP) {
      data.app_data = this.initAPPCategoryData()
    }
    if (data.selected_category === QrGeneratorNavigatorCategories.WEBSITE) {
      data.website_data = this.initWEBSITECategoryData()
    }
    if (data.selected_category === QrGeneratorNavigatorCategories.EVENT) {
      data.event_data = this.initEventCategoryData()
    }
    if (data.selected_category === QrGeneratorNavigatorCategories.COUPONS) {
      data.coupons_data = this.initCouponsCategoryData()
    }
    if (data.selected_category === QrGeneratorNavigatorCategories.BUSINESS_PAGE) {
      data.businesspage_data = this.initBusinessPageCategoryData()
    }
    if (data.selected_category === QrGeneratorNavigatorCategories.RESTAURANT_MENU) {
      data.restaurant_menu_data = this.initRestaurantMenu(currency)
    }
    if (data.selected_category === QrGeneratorNavigatorCategories.LONG_PLAIN_TEXT) {
      data.long_plain_text_data = this.initLongPlainTextCategoryData()
    }
    if (data.selected_category === QrGeneratorNavigatorCategories.FEEDBACK) {
      data.feedback_data = this.initFeedbackCategoryData()
    }
    if (data.selected_category === QrGeneratorNavigatorCategories.IMAGES) {
      data.images_data = this.initImagesCategoryData()
    }
    if (data.selected_category === QrGeneratorNavigatorCategories.MP3) {
      data.mp3_data = this.initMP3CategoryData()
    }
    if (data.selected_category === QrGeneratorNavigatorCategories.PDF) {
      data.pdf_data = this.initPDFCategoryData()
    }
    if (data.selected_category === QrGeneratorNavigatorCategories.RATING) {
      data.rating_data = this.initRatingCategoryData()
    }
    if (data.selected_category === QrGeneratorNavigatorCategories.SOCIAL_MEDIA) {
      data.socials_data = this.initSocialMediaCategoryData()
    }
    if (data.selected_category === QrGeneratorNavigatorCategories.VACANCY) {
      data.vacancy_data = this.initVacancyCategoryData()
    }
    if (data.selected_category === QrGeneratorNavigatorCategories.VCARD_PRO) {
      data.vcardpro_data = this.initVCardProCategoryData()
    }
    if (data.selected_category === QrGeneratorNavigatorCategories.LOCATION_PLUS) {
      data.location_data = this.initLocationCategoryData(true)
    }
    if (data.selected_category === QrGeneratorNavigatorCategories.VIDEOS) {
      data.videos_data = this.initVideoCategoryData()
    }
    if (data.selected_category === QrGeneratorNavigatorCategories.PET) {
      data.pet_data = this.initPetCategoryData()
    }
    if (data.selected_category === QrGeneratorNavigatorCategories.MEMORIES) {
      data.memories_data = this.initMemoriesCategoryData()
    }

    data.activation_code_ots_qr = activation_code_ots_qr
    data.active_step = QrGeneratorNavigatorSteps.CUSTOMIZE
    session_info.qr_generating.last_viewed_category = data.selected_category
    session_info.qr_generating.next_preferred_step = QrGeneratorNavigatorSteps.CUSTOMIZE
    const _index = session_info.qr_generating.drafts.findIndex(d => d.selected_category === data.selected_category)
    if (_index === -1)
      session_info.qr_generating.drafts.push(data)
    else
      session_info.qr_generating.drafts[_index] = data

    coreService.setSessionData(session_info, false)
    if (coreService.isLoggedIn()) {
      coreService.goHome()
      return true
    }
    return false
  }

  getCodeType(category: QrGeneratorNavigatorCategories): QrCodeTypes {
    if (![QrGeneratorNavigatorCategories.SMS, QrGeneratorNavigatorCategories.EMAIL, QrGeneratorNavigatorCategories.URL, QrGeneratorNavigatorCategories.LOCATION, QrGeneratorNavigatorCategories.TEXT, QrGeneratorNavigatorCategories.VCARD, QrGeneratorNavigatorCategories.BITCOIN, QrGeneratorNavigatorCategories.WIFI].includes(category)) return QrCodeTypes.DYNAMIC
    return QrCodeTypes.STATIC
  }

  getFrameSettings(): Array<QrFrameSetting> {
    return [
      { type: QrGeneratorFrameTypes.STYLE1, width: 788, height: 974 },
      { type: QrGeneratorFrameTypes.STYLE2, width: 750, height: 990 },
      { type: QrGeneratorFrameTypes.STYLE3, width: 740, height: 974 },
      { type: QrGeneratorFrameTypes.STYLE4, width: 780, height: 970 },
      { type: QrGeneratorFrameTypes.STYLE5, width: 740, height: 920 },
      { type: QrGeneratorFrameTypes.STYLE6, width: 550, height: 940 },
    ]
  }
}
