import moment from 'moment';
import message from 'vanilla-antd-message'
import { Location } from '@angular/common';
import { APP_CONFIG } from '../config/config';
import { HttpClient, HttpEvent, HttpEventType } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { IAppConfig } from '@app/store/models/config.interface';
import { Observable, firstValueFrom, lastValueFrom, map } from 'rxjs';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { countries, currentFullDate, defaultDateFormat, ErrorCode, ErrorMessage, QrGeneratorNavigatorCategories, SocialsInterfaceTypes, timeExpired, today, ucFirst, UserLargeFileUploadModes } from 'shared-library';

declare const require: any
declare const Flickity: any
declare const bootstrap: any
const dataURLtoBlob = require('blueimp-canvas-to-blob')

@Injectable({
  providedIn: "root",
})
export class FunctionsService {

  tooltipList = []
  private tooltipTimer: any;
  flkty: { selector: string; instance: any }[] = [];
  private base64_from_urls: { image_url: string; result: string; }[] = []
  private presigned_policies_array: {
    team_id: string;
    extension: string;
    creation_date: string;
    policies: PRESIGNED_POLICY[];
    module: UserLargeFileUploadModes;
  }[] = []

  constructor(
    private routes: Router,
    private httpClient: HttpClient,
    private sanitizer: DomSanitizer,
    @Inject(APP_CONFIG) private config: IAppConfig) {
  }

  dateFuture(future_date: string, current_date: string = "", inclusive: boolean = true): boolean {
    let fDate: number, cDate: number;
    current_date = current_date || today()
    fDate = Date.parse(future_date.replace(/-/g, "/"));
    cDate = Date.parse(current_date.replace(/-/g, "/"));
    return inclusive ? fDate >= cDate : fDate > cDate;
  }

  dateDifference(from: string, to: string): number {
    const dateone: any = this.getDateCustom(from);
    const datetwo: any = this.getDateCustom(to);
    return (datetwo - dateone) / 1000 / 60 / 60 / 24;
  }

  gotoRoute(a: string): void {
    this.routes.navigateByUrl(a);
  }

  goBack(location: Location): void {
    location.back();
  }

  getCountries() {
    return countries
  }

  ucfirst(w: string) {
    return ucFirst(w)
  }

  closeAllModals() {
    document.querySelectorAll('.modal').forEach(modal => bootstrap.Modal.getInstance(modal)?.hide())
    document.querySelectorAll('.offcanvas').forEach(modal => bootstrap.Offcanvas.getInstance(modal)?.hide())
  }

  initializeTooltip() {
    clearTimeout(this.tooltipTimer)
    this.tooltipTimer = setTimeout(() => {
      const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
      this.tooltipList.map(tp => tp.dispose())
      this.tooltipList = tooltipTriggerList.map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl))
      this.logToConsole(this.tooltipList)
    }, 1000)
  }

  formatDate(date: string, add_time = false, add_day = false): string {
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
    const dayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const date_new = this.getDateCustom(date, "");
    const day = date_new.getDate();
    const monthIndex = date_new.getMonth();
    const year = date_new.getFullYear();
    return (add_day ? dayNames[date_new.getDay()] + " " : "") + day + " " + monthNames[monthIndex] + " " + year + (add_time ? ` ${this.ampm(date_new)}` : "");
  }

  ucWords(w: string): string {
    if (!w) return w
    let ww = w.trim().toLowerCase().split(" ");
    return ww.filter(ww => ww.trim() ? true : false).map(ww => this.ucfirst(ww)).join(" ")
  }

  isEmail(em: string): boolean {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(em).toLowerCase());
  }

  sortAlphabetically(q: Array<string>): Array<string> {
    q.sort(function (a, b) {
      if (a < b) return -1;
      if (a > b) return 1;
      return 0;
    });
    return q;
  }

  sortByAlphabets(arr: any[], key: string): any[] {
    let ret = arr.sort(function (a, b) {
      a[key] = a[key] ? a[key] : "";
      b[key] = b[key] ? b[key] : "";
      const textA = a[key].toUpperCase();
      const textB = b[key].toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
    return ret;
  }

  sortByCreationDate<T>(arr: T[], key = "creation_date", is_asc = false): any[] {
    is_asc = is_asc || false
    let ret = arr.sort((a, b) => moment(b[key]).utc(true).unix() - moment(a[key]).utc(true).unix());
    return is_asc ? ret.reverse() : ret;
  }

  currentMonthStartEndDates(): string[] {
    let date = this.getDateCustom()
    let first_day_of_month = new Date(date.getFullYear(), date.getMonth(), 1)
    let last_day_of_month = new Date(date.getFullYear(), date.getMonth() + 1, 0)
    return [this.dateDBFormat(first_day_of_month), this.dateDBFormat(last_day_of_month)];
  }

  getDateCustom(d: string = "", utc = " UTC"): Date {
    if (!d) {
      return new Date()
    }
    return new Date(d.replace(/-/g, "/") + utc)
  }

  ltrim(what: string, trimWhat: string = " "): string {
    let p_checker = what.trim();
    if ("" != p_checker && p_checker[0] == trimWhat) {
      return this.ltrim(p_checker.substring(1, p_checker.length), trimWhat);
    }
    return p_checker;
  }

  sanitizeURL(url: string): SafeUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    // return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  urlRegex() {
    return /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
  }

  ipRegex() {
    return /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
  }

  mobileRegex() {
    return /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
  }

  longitudeRegex() {
    return /^-?((?:1[0-7]|[1-9])?\d(?:\.\d{1,10})?|180(?:\.0{1,10})?)$/;
  }

  latitudeRegex() {
    return /^-?([1-8]?\d(?:\.\d{1,10})?|90(?:\.0{1,10})?)$/;
  }

  colorRegex() {
    return "^#[0-9a-fA-F]{8}$|#[0-9a-fA-F]{6}$|#[0-9a-fA-F]{4}$|#[0-9a-fA-F]{3}$";
  }

  initThirdParty(selector = '.workspace-carousel', initialIndex = 0) {
    this.flkty.find(flky => flky.selector === selector)?.instance?.destroy()
    this.flkty.filter(flky => flky.selector !== selector)
    setTimeout(() => {
      const elem = document.querySelector(selector);
      if (elem) {
        this.flkty.push({
          selector,
          instance: new Flickity(elem, {
            // options
            initialIndex,
            contain: true,
            cellAlign: 'left',
            accessibility: true,
          })
        })
      }
    })
  }

  getSocialRegexes(type: SocialsInterfaceTypes): RegExp {
    switch (type) {
      // case SocialsInterfaceTypes.facebook:
      //     return /(?:http(s)?:\/\/)?(?:www\.)?(?:facebook|fb)\.com\/(?!<profile>(?![A-z]+\.php)(?!marketplace|gaming|watch|me|messages|help|search|groups)[A-z0-9_\-\.]+)\/?/g
      // case SocialsInterfaceTypes.instagram:
      //     return /(?:http(s)?:\/\/)?(?:[A-z]+\.)?instagram.com\/([A-z0-9-\_]+)\/?/g
      // case SocialsInterfaceTypes.whatsapp:
      //     return /^[0-9]*$/g
      // case SocialsInterfaceTypes.youtube:
      //     return /(?:http(s)?:\/\/)?(?:[A-z]+\.)?youtube.com\/([A-z0-9-\_]+)\/?/g
      // case SocialsInterfaceTypes.linkedin:
      //     return /(?:http(s)?:\/\/)?(?:[A-z]+\.)?linkedin.com\/([A-z0-9-\_]+)\/?/g
      // case SocialsInterfaceTypes.twitter:
      //     return /(?:http(s)?:\/\/)?(?:[A-z]+\.)?twitter.com\/([A-z0-9-\_]+)\/?/g
      // case SocialsInterfaceTypes.tiktok:
      //     return /(?:http(s)?:\/\/)?(?:[A-z]+\.)?tiktok.com\/([A-z0-9-\_]+)\/?/g
      // case SocialsInterfaceTypes.onlyfans:
      //     return /(?:http(s)?:\/\/)?(?:[A-z]+\.)?onlyfans.com\/([A-z0-9-\_]+)\/?/g
      // case SocialsInterfaceTypes.pinterest:
      //     return /(?:http(s)?:\/\/)?(?:[A-z]+\.)?pinterest.com\/([A-z0-9-\_]+)\/?/g
      case SocialsInterfaceTypes.website:
      default:
        return this.urlRegex()
    }
  }

  is_valid_url(url: string): boolean {
    if (!url) return false
    url = `${url}`.trim();
    const pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator

    let method2 = (str: string): boolean => {
      const a = document.createElement("a");
      a.href = str;
      return (a.host as unknown as boolean) && a.host != window.location.host;
    };

    return !!pattern.test(url) || method2(url);
  }

  is_valid_ip(ip: string): boolean {
    return this.ipRegex().test(ip);
  }

  months(full = false): string[] {
    if (!full) return ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
    return ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  }

  randomString(length = 5): string {
    let result = "";
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  randomNumber(): number {
    return Math.floor(Math.random() * 100000000) + 1
  }

  copyToClipboard(whatToCopy: string, note_text = "Copied"): boolean {
    let selBox = document.createElement("textarea");
    selBox.style.position = "fixed";
    selBox.style.left = "0";
    selBox.style.top = "0";
    selBox.style.opacity = "0";
    selBox.value = whatToCopy;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    selBox.setSelectionRange(0, 99999); /* For mobile devices */
    if (!navigator.clipboard) {
      this.showErrorMessage(ErrorMessage.TEXT_COULD_NOT_BE_COPIED)
      return false
    }
    navigator.clipboard.writeText(selBox.value); // Works in ssl pages only
    document.body.removeChild(selBox);
    if (note_text) this.showSuccessMessage(note_text == "Copied" ? `${note_text} "${whatToCopy}"` : note_text)
    return true;
  }

  dateDBFormat(date: Date, include_time = true): string {
    const force2 = (d: number): string => {
      return d < 10 ? `0${d}` : d.toString()
    }
    let d = date || this.getDateCustom()
    let dd = [d.getFullYear(), force2(d.getMonth() + 1), force2(d.getDate())]
    let ddd = [force2(d.getHours()), force2(d.getMinutes()), force2(d.getSeconds())]
    return include_time ? `${dd.join('-')} ${ddd.join(':')}` : dd.join('-')
  }

  datePimped(d: string, add_time: boolean = false): string {
    let dd = this.getDateCustom(d);
    let mm = this.months();
    if (!add_time) {
      return `${mm[dd.getMonth()]} ${dd.getDate()}, ${dd.getFullYear()}`;
    }
    return `${mm[dd.getMonth()]} ${dd.getDate()}, ${dd.getFullYear()} ${this.ampm(dd)}`;
  }

  ampm(dt: Date): string {
    let hours = dt.getHours();
    let minutes: number | string = dt.getMinutes();
    let ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    return `${hours}:${minutes} ${ampm}`;
  }

  pluralize(word: string, n: number): string {
    const logic = (): string => {
      // Words ending in 'y' with a consonant before it: change 'y' to 'ies'
      if (word.endsWith('y') && !['a', 'e', 'i', 'o', 'u'].includes(word[word.length - 2].toLowerCase())) {
        return word.slice(0, -1) + 'ies';
      }

      // Words ending in 's', 'x', 'z', 'ch', 'sh': add 'es'
      if (['s', 'x', 'z', 'ch', 'sh'].some(suffix => word.endsWith(suffix))) {
        return word + 'es';
      }

      // Add 's' to other words
      return word + 's';
    }
    return n > 1 ? logic() : word;
  }

  array_unique<T>(a: T[]): T[] {
    return a.filter((value, index, self) => {
      return self.indexOf(value) === index;
    });
  }

  convertSeconds(seconds: number): string {
    let result = new Date(seconds * 1000).toISOString().substr(11, 8)
    if (result.startsWith("00:")) {
      // Remove hour
      result = result.replace("00:", "")
    }
    return result
  }

  getUnixTimestamp(): number {
    return +new Date()
  }

  async base64FromFile(file: File): Promise<BASE_64_ENCODED> {
    return new Promise((done, nope) => {
      const reader: FileReader | any = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        done(this.prepBase64(reader.result));
      };
      reader.onerror = (err: any) => {
        this.logToConsole(`getBase64fromFile failed.`, err)
        nope(err);
      };
    });
  }

  prepBase64(str: string): BASE_64_ENCODED {
    const spliced = str.split(',');
    const header = spliced[0];
    spliced.shift();
    return {
      header,
      body: spliced.join('')
    }
  }

  prepCategoryTitle(s: QrGeneratorNavigatorCategories): string {
    switch (s) {
      case QrGeneratorNavigatorCategories.APP:
        return "Mobile APP"
      case QrGeneratorNavigatorCategories.MP3:
        return "MP3"
      case QrGeneratorNavigatorCategories.PDF:
        return "PDF"
      case QrGeneratorNavigatorCategories.COUPONS:
        return "Coupon"
      case QrGeneratorNavigatorCategories.LONG_PLAIN_TEXT:
        return "Long Text"
      case QrGeneratorNavigatorCategories.VCARD_PRO:
        return "vCard Pro"
      case QrGeneratorNavigatorCategories.VIDEOS:
        return "Youtube Videos"

      default:
        return s.split("-").map(_s => this.ucfirst(_s)).join(" ")
    }
  }

  visitPageText(category: QrGeneratorNavigatorCategories): string {
    switch (category) {
      case QrGeneratorNavigatorCategories.IMAGES:
      case QrGeneratorNavigatorCategories.MEMORIES:
        return 'Preview gallery'
      case QrGeneratorNavigatorCategories.LOCATION_PLUS:
        return 'Preview location'
      case QrGeneratorNavigatorCategories.RESTAURANT_MENU:
        return 'Preview menu'
      default:
        return 'Preview page'
    }
  }

  forceUrl(u: string): string {
    if (!this.is_valid_url(u)) return "#"
    return (u.toLowerCase().indexOf('http://') === 0 || u.toLowerCase().indexOf('https://') === 0) ? u : `http://${u}`
  }

  // https://www.cssscript.com/ant-design-toast-notification/
  showSuccessMessage(m: string, duration = 3000): void {
    message.success(m, duration);
  }

  showErrorMessage(m: ErrorCode | string = null, duration = 3000): void {
    this.logToConsole(m)
    let _m = m && typeof m === typeof "" ? (ErrorMessage[m] || m) : ErrorMessage.SOMETHING_WENT_WRONG_WHILE_PROCESSING_REQUEST
    // if (m && m.error) {
    //     _m = m.error
    // }
    message.error(_m, duration);
  }

  showAlertMessage(m: string, duration = 3000): void {
    message.info(m, duration);
  }

  showWarningMessage(m: string, duration = 3000): void {
    message.warn(m, duration);
  }

  async getQueryString(activatedRoute: ActivatedRoute, whichOne: string = "goto"): Promise<string> {
    return new Promise((yeap) => {
      let qs = activatedRoute.snapshot.paramMap.get(whichOne);
      if (qs) {
        yeap(qs)
        return
      }
      activatedRoute.queryParams.subscribe(params => {
        yeap(params[whichOne] || "");
      });
    })
  }

  getParameterByName(name: string, url: string) {
    name = name.replace(/[\[\]]/g, '\\$&');
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  percentageCalculator(p: number, b: number): number { //returns 10% of 34000
    if (!b || !p) {
      return 0;
    }
    let t = b * (p * 0.01)
    return t == parseInt(`${t}`) ? parseInt(`${t}`) : this.toFixed(t);
  }

  percentageCalculatorV2(s: number, b: number, p = 100): number { //returns percentage of 10 in 34000
    if (!b || !s) {
      return 0;
    }
    let t = parseFloat(((((s / b) * 10) / 10) * p).toFixed(2));
    return t == parseInt(`${t}`) ? parseInt(`${t}`) : t;
  }

  toFixed(n: number, decimal_places = 2): number {
    return parseFloat(n.toFixed(decimal_places))
  }

  logToConsole(w: any, other: any = null): void {
    if (!environment.production) {
      // console.clear()
      console.log(`%c Data logged`, 'background: black; color: white; padding: 10px;')
      other ? console.log(w, other) : console.log(w)
    }
  }

  encryptionHandler(str: string, is_encrypt = true): string {
    try {
      return is_encrypt ? this.encodeURl(str) : this.decodeURl(str)
    } catch (e) {
      this.logToConsole(e)
    }
    return str
  }

  encryptionScanner(data: any[], scan_for: string): any[] {
    data = data.map(d => {
      if (d[scan_for]) {
        d[`${scan_for}_enc`] = this.encryptionHandler(d[scan_for])
      }
      return d
    })
    return data
  }

  decodeURl(url: string): string {
    return decodeURIComponent(url)
  }

  encodeURl(url: string): string {
    return encodeURIComponent(url)
  }

  stringSizeInMB(str: string): number {
    let bytes = 0, len = str.length, codePoint: number, next: number, i: number;
    for (i = 0; i < len; i++) {
      codePoint = str.charCodeAt(i);

      // Lone surrogates cannot be passed to encodeURI
      if (codePoint >= 0xD800 && codePoint < 0xE000) {
        if (codePoint < 0xDC00 && i + 1 < len) {
          next = str.charCodeAt(i + 1);

          if (next >= 0xDC00 && next < 0xE000) {
            bytes += 4;
            i++;
            continue;
          }
        }
      }
      bytes += (codePoint < 0x80 ? 1 : (codePoint < 0x800 ? 2 : 3));
    }
    return bytes / 1000000;
  }

  abs(n: number): number {
    return Math.abs(n)
  }

  scrollToTop(cc: ".sidebar-contents" | ".qr-content-container" | ".qr-wrapper" | ".preview-main-wrapper" = ".sidebar-contents"): boolean {
    if ([".sidebar-contents", ".preview-main-wrapper"].includes(cc)) {
      const elements = document.querySelectorAll<HTMLInputElement>(cc)
      if (elements.length) {
        elements[0].scrollTop = 0
      }
      return true
    }

    if ([".qr-content-container", ".qr-wrapper"].includes(cc)) {
      const elements = document.querySelectorAll<HTMLInputElement>(cc)
      if (elements.length) {
        setTimeout(function () {
          elements[0].scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }, 500);
      }
      return true
    }
    return false
  }

  expiredSignedUrl(src: string): boolean {
    const url_params = new URLSearchParams(src);
    const expiry_date = +url_params.get('Expires');
    if (!expiry_date) return false
    let d = +this.getUnixTimestamp().toString().substring(0, 10)
    return d >= expiry_date
  }

  prepWhatsappNo(w: string | number): string {
    return "https://wa.me/" + this.ltrim(`${w}`, '+').replace(" ", "")
  }

  validateColorCode(c: string): boolean {
    return /^#[0-9A-F]{6}$/i.test(c) || /^#[0-9A-F]{8}$/i.test(c)
  }

  convertTimeFrom24to12Format(_time: string): string {
    let time: RegExpMatchArray | string[] = _time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [_time];
    if (time.length > 1) { // If time format correct
      time = time.slice(1);  // Remove full string match value
      time[5] = +time[0] < 12 ? ' am' : ' pm'; // Set AM/PM
      time[0] = (+time[0] % 12 || 12).toString(); // Adjust hours
    }
    return time.join('');
  }

  formatBytes(bytes: number, decimals = 2): string {
    if (!bytes) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  findInitials(name: string): string {
    return name.split(" ").filter(n => n).map(n => n.charAt(0)).slice(0, 2).join("")
  }

  deepCopy<T>(json: T): T {
    return JSON.parse(JSON.stringify(json))
  }

  /**
   *
   * @param file
   * @param extension it should be lowercase (ex. png|jpeg)
   * @param _module
   * @param content_type
   * @param return_full_url
   * @param team_id
   * @returns
   */
  async uploadFile(file: BASE_64_ENCODED, extension: UploadFileExtensions, _module: UserLargeFileUploadModes = UserLargeFileUploadModes.MAIN, content_type = "image/jpeg", return_full_url = true, team_id = "", estimated_qty = 0): Promise<string> {

    const url = [UserLargeFileUploadModes.PUBLIC, UserLargeFileUploadModes.CV, UserLargeFileUploadModes.QRASSETS].includes(_module) ? this.config.apiEndpoints.resources.upload : this.config.apiEndpoints.user.upload

    const getPolicy = async (): Promise<PRESIGNED_POLICY> => {
      const from_local = this.presigned_policies_array.findIndex(pp =>
        pp.policies.length &&
        pp.module === _module &&
        pp.extension === extension &&
        !timeExpired(moment(pp.creation_date).add(55, "minutes").format(defaultDateFormat)) &&
        (_module !== UserLargeFileUploadModes.QRASSETS || pp.team_id === team_id)
      )
      if (from_local !== -1) return this.presigned_policies_array[from_local].policies.pop()
      const responseObservable = this.httpClient.get<{ data: PRESIGNED_POLICY[]; }>(`${url}${_module}`, { params: { ...(team_id ? { team_id } : {}), extension, quantity: estimated_qty || 10 } });
      const { data } = await firstValueFrom(responseObservable);
      const policy = data.pop()
      this.presigned_policies_array.push({
        team_id,
        extension,
        policies: data,
        module: _module,
        creation_date: currentFullDate(),
      })
      return policy;
    }

    //Initiate upload
    const result = await getPolicy()
    const policy_decoded = JSON.parse(atob(result.fields.Policy))

    const formData = new FormData()
    formData.append('key', result.fields.key);
    const acl = policy_decoded.conditions.find(c => c?.acl)
    if (acl) {
      formData.append('acl', acl.acl);
    }
    if (!content_type) {

      // Map file extensions to MIME types
      const mimeTypeMap = {
        'jpg': 'image/jpeg',
        'jpeg': 'image/jpeg',
        'png': 'image/png',
        'gif': 'image/gif',
        'bmp': 'image/bmp',
        'tiff': 'image/tiff',
        'webp': 'image/webp',
        'mp4': 'video/mp4',
        'webm': 'video/webm',
        'pdf': 'application/pdf',
        'flv': 'video/x-flv',
        'wmv': 'video/x-ms-wmv',
        'mov': 'video/quicktime',
        'mkv': 'video/x-matroska',
        'avi': 'video/x-msvideo'
      };

      content_type = mimeTypeMap[extension];
      if (!content_type && !file.file) {
        content_type = file.header.replace("data:", "")
        content_type = content_type.replace(";base64", "")
      }
    }

    formData.append('Content-Type', content_type);
    formData.append('Policy', result.fields.Policy);
    formData.append('X-Amz-Algorithm', result.fields['X-Amz-Algorithm']);
    formData.append('X-Amz-Credential', result.fields['X-Amz-Credential']);
    formData.append('X-Amz-Date', result.fields['X-Amz-Date']);
    formData.append('X-Amz-Signature', result.fields['X-Amz-Signature']);
    formData.append('bucket', result.fields.bucket);

    if (file.file) {
      formData.append('file', file.file)
    } else {
      try {
        formData.append('file', dataURLtoBlob([file.header, file.body].join(',')))
      } catch (error) {
        formData.append('file', [file.header, file.body].join(','))
      }
    }

    await lastValueFrom(this.httpClient.post(`${result.url}/`, formData, { headers: { skip: "true" }, reportProgress: true }))
    let ret = return_full_url ? environment.files_url : ""
    ret += result.fields.key
    return ret
  }

  openPopUp(__url: string) {
    const new_window = window.open(__url, '_blank')
    if (!new_window || new_window.closed || typeof new_window.closed == 'undefined') {
      this.showAlertMessage("We attempted to open the document in a new window but a popup blocker is preventing it from opening, please disable popup blockers for this site.")
      return
    }
    new_window?.focus()
  }

  async base64FromURL(image_url: string): Promise<string> {
    const from_cache = this.base64_from_urls.find(im => im.image_url === image_url)
    if (from_cache) return from_cache.result
    const res = await fetch(`${image_url}?crossorigin`);
    const blob = await res.blob();
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        const result = reader.result as string
        this.base64_from_urls.push({ image_url, result })
        resolve(result);
      }, false);
      reader.onerror = () => {
        return reject(this);
      };
      reader.readAsDataURL(blob);
    })
  }

  getImage(imageUrl: string): Promise<Observable<Blob>> {
    return this.httpClient.get(imageUrl, { responseType: 'blob', headers: { "skip-message": "true", "skip": "true" } }).toPromise() as any as Promise<Observable<Blob>>;
  }

  saveToDisk(key: string, value: string) {
    try {
      localStorage.setItem(key, value);
    } catch (error) {
      // Local storage is not allowed in cognito mode, try session storage instead
      sessionStorage.setItem(key, value);
    }
  }

  retrieveFromDisk(key: string): string {
    try {
      return localStorage.getItem(key);
    } catch (error) {
      // Local storage is not allowed in cognito mode, try session storage instead
    }
    try {
      return sessionStorage.getItem(key);
    } catch (error) {
      // Local storage is not allowed in cognito mode, try session storage instead
    }
    return null
  }

  hexToRgb(hex: string): number[] {
    return hex.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i
      , (m, r, g, b) => '#' + r + r + g + g + b + b)
      .substring(1).match(/.{2}/g)
      .map(x => parseInt(x, 16))
  }

  rgbToHex(r: number, g: number, b: number): string {
    return '#' + [r, g, b].map(x => {
      const hex = x.toString(16)
      return hex.length === 1 ? '0' + hex : hex
    }).join('')
  }

  schemaStillSame(object1: any, object2: any): boolean {
    const self = this; // Get a reference to your object.

    const isObject = (object: any) => object != null && typeof object === 'object' && !Array.isArray(object);
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);
    if (keys1.length !== keys2.length && isObject(object1) && isObject(object2)) {
      return false;
    }

    for (const key of keys1) {
      const val1 = object1[key];
      const val2 = object2[key];
      const areObjects = isObject(val1) && isObject(val2);
      if (
        (areObjects && !self.schemaStillSame(val1, val2))
        || (!areObjects && val1 !== val2)
      ) {
        return false
      }
    }
    return true;
  }

  async wasteTimeABit(seconds = 1) {
    await new Promise(resolve => setTimeout(() => resolve(true), seconds * 1000))
  }

  generateMapUrl(latitude: number, longitude: number): string {
    return "https://www.google.com/maps/search/?api=1&query=" + latitude + "," + longitude;
  }

}

export interface BASE_64_ENCODED {
  file?: File;
  body: string;
  header: string;
}

interface PRESIGNED_POLICY { fields: { Policy: string; bucket: string; "X-Amz-Algorithm": string; "X-Amz-Credential": string; "X-Amz-Date": string; "X-Amz-Signature": string; key: string; }; url: string; }
export type UploadFileExtensions = "png" | "svg" | "jpg" | "jpeg" | "pdf"
