import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { RecaptchaV3Module, RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpConfigInterceptor } from './interceptors/httpconfig.interceptor';
import { AppConfig, APP_CONFIG } from './modules/core/config/config';
import { reducers, metaReducers } from './store/reducers';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { SharedModule } from './modules/shared/shared.module';
import { AppEffects } from './store/effects/app.effects';
import { SocialLoginModule, SocialAuthServiceConfig, GoogleLoginProvider, FacebookLoginProvider } from '@abacritt/angularx-social-login';
import { NgcCookieConsentModule } from 'ngx-cookieconsent';
import { AdminEffects } from './store/effects/admin.effects';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}


@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        SharedModule,
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        RecaptchaV3Module,
        SocialLoginModule,
        NgcCookieConsentModule.forRoot({
            cookie: {
                domain: environment.app_url
            },
            position: "bottom-left",
            theme: "classic",
            blacklistPage: ["qrs/ns9HKji3K"],
            // blacklistPage: [new RegExp("/\/qrs\//gm")],
            palette: {
                popup: {
                    background: "#ef5454",
                    text: "#ffffff",
                    link: "#ffffff"
                },
                button: {
                    background: "#FEF5F6",
                    text: "#ef5454",
                    border: "#fef5f6"
                }
            },
            type: "info",
            content: {
                message: "BrandQRCodes uses cookies to ensure that you have the best possible experience on our site.",
                dismiss: "Got it!",
                deny: "Refuse cookies",
                link: "",
                href: "",
                policy: "Cookie Policy"
            }
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
            defaultLanguage: 'en'
        }),
        StoreModule.forRoot(reducers, { metaReducers }),
        !environment.production ? StoreDevtoolsModule.instrument() : [],
        EffectsModule.forRoot([
            AppEffects,
            AdminEffects,
        ]),
    ],
    providers: [
        CookieService,
        { provide: APP_CONFIG, useValue: AppConfig },
        { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.google_keys.recaptcha },
        { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true },
        {
            provide: 'SocialAuthServiceConfig',
            useValue: {
                autoLogin: false,
                providers: [
                    {
                        id: GoogleLoginProvider.PROVIDER_ID,
                        provider: new GoogleLoginProvider(
                            '41192512387-drectggqd1ipg9o5qp8do29s8k91qikt.apps.googleusercontent.com'
                        )
                    },
                    // {
                    //   id: FacebookLoginProvider.PROVIDER_ID,
                    //   provider: new FacebookLoginProvider('clientId')
                    // }
                ],
                onError: (err) => {
                    console.error(err);
                }
            } as SocialAuthServiceConfig,
        },
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
